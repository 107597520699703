.App-header {
  width: 100%;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2rem;
  background-color: var(--cfa-purple-dark);

  a {
    text-decoration: none;
  }

  .home-link-and-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    border: 4px solid transparent;

    .home-link {
      display: flex;
      flex-wrap: wrap;

      .App-logo {
        width: 10rem;
        display: inline-block;
        pointer-events: none;
        margin-right: 1.3rem;
      }
    }

    .App-title {
      white-space: nowrap;
      font-size: 2rem;
      font-weight: bold;
      color: var(--cfa-white);
      font-family: var(--font-family-header);
    }
  }
  
  .App-title {
    white-space: nowrap;
    font-size: 2rem;
    font-weight: bold;
    color: var(--cfa-white);
    font-family: var(--font-family-header);
  }

  .home-link:focus,
  .home-link:hover {
    background-color: var(--cfa-blue-dark);
  }

  @media only screen and (max-width: 800px) {
    .home-link-and-title {
      padding: 0.5rem;

      .home-link {
        justify-content: start;

        .App-logo {
          width: 7rem;
          text-align: left;
        }
      }

      .App-title {
        font-size: 1.75rem;
      }
    }
  }
}
