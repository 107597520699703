.column-header__contents {
 display: flex;
  padding-right: 0rem;
  align-items: stretch;
  overflow-wrap: anywhere;
  align-self: center;

  > *:not(:first-child) {
    margin-left: 1rem;   
  }

  input {
    width:100%;
    box-sizing: content-box;
  }

  .sort-button {
    width: 1rem;
    min-width: 1rem;
    padding: 0;
    height:2rem;
  }


  .sort-arrows {
    position: relative;
    height: 100%;
    width: 100%;

    .sort-arrow {
      height: 45%;
      padding: 2.5%;
      opacity: 0.5;
      position: absolute;

      &.sorted {
        opacity: 1;
      }

      &.asc {
        transform: translate(-50%, 10%) rotate(-90deg);
      }

      &.desc {
        transform: translate(-50%, 90%) rotate(90deg);
      }
    }
  }
}


@media screen and (max-width:800px) {
  .column-header__contents {
      display: flex;
      padding-right: 2rem;
      align-items: stretch;
      width:calc(100% - 2rem);
  
    input {
      box-sizing: content-box;
    }
  }
}

.organization-column {
  width: 200px
}

.name-column {
  width: 150px;
}

// sets width of amount left over after fixed width columns
.description-column {
  width: 67%;
}

.open-issues-column {
  width: 80px;
}

.last-pushed-within-column {
  width: 90px;
}

.topics-column {
  width: 33%;
}

// https: //medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3

.column-display-option {
   font-size: 1rem;
   padding-bottom: 10px;
   padding-right: 25px;
}

.column-display-option input[type="checkbox"] {
  opacity: 0;
//   height: 1px;
//   width: 1px;
}

.column-display-option label {
  position: relative;
  display: inline-block;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 3px;
}

.column-display-option label::before,
.column-display-option label::after {
  position: absolute;
  margin-left: -18px;
  content: "";
  background-color: var(--cfa-blue-lighter);
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.column-display-option label::before {
  height: 16px;
  width: 16px;

  border: 1px solid;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.column-display-option label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;

  transform: rotate(-45deg);

  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.column-display-option input[type="checkbox"]+label::after {
  content: none;
}

/*Unhide on the checked state*/
.column-display-option input[type="checkbox"]:checked+label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.column-display-option input[type="checkbox"]:focus+label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
