.leaflet-container {
  position: relative;
  height: 100%;

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    border: 3px solid transparent;

    &:hover,
    &:focus {
      border: 3px solid var(--cfa-blue);
    }
  }

  // .states-group {
  //   path {
  //     fill: transparent;
  //     stroke: var(--cfa-grey-dark);
  //     cursor: pointer;
  //   }

  //   path:hover,
  //   path:focus {
  //     outline: none;
  //     stroke: var(--cfa-blue);
  //     stroke-width: 3px;
  //   }

  //   path:focus {
  //     fill: var(--cfa-grey);
  //   }
  // }

  // path.state-border {
  //   fill: transparent;
  //   stroke: var(--cfa-grey-dark);
  //   cursor: pointer;
  // }

  .brigade-point {
    circle {
      fill-opacity: 0.5;
      stroke-width: 4px;
      stroke: var(--cfa-purple);
      fill: var(--cfa-purple);
      cursor: pointer;

      &:hover,
      &:focus {
        fill-opacity: 1;
        fill: var(--cfa-blue);
      }
    }
    &.selected circle {
      stroke: var(--cfa-yellow);
      fill: var(--cfa-yellow);
    }
  }

  .reset-button {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 999;
  }
}
