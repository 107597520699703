.button {
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.3rem 0.25rem 0.3rem 0.25rem;
  &.link-button {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
    background-color: inherit;
    padding: unset;
    border: none;
    border: 2px solid transparent;
    &:hover,
    &:focus {
      border: 2px solid rgba($color: #fff, $alpha: 0.5);
    }
  }
}
