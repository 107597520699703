@import url('./assets/styles/colors.css');
@import url('./assets/styles/fonts.css');

html,
body {
  overflow-x: hidden;
}

.attention {
  font-size: 150%;
  font-weight: bold;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  padding-bottom: 3rem;
}

a {
  color: inherit;

  &:hover,
  &:focus {
    color: var(--cfa-grey-dark);
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}

.display-inline {
  display: inline;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.separator {
  border-bottom: 3px solid ;
  color: var(--cfa-purple-dark);
  height: 0px;
  padding: 0px;
  margin: 6px 0px;

}
