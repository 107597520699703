/* inspired by: https://css-tricks.com/responsive-data-tables/ */
/* In this implementation, we want our columns at the top, so we 
remove the style that would otherwise force the regular thead to 
move offscreen on mobile. See documentation for more details 
https://react-super-responsive-table.netlify.app/*/

.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

.mobile {
  display: none;
}

.mobileheader {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .mobileheader {
    display: block;
  }

  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    /* padding-left: calc(50% + 10px) !important; */
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td.pivoted:nth-child(1) {
    font-weight: bold;
    font-size: large;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}
