.projects-table {
  width: 100%;
  height: auto;
  

  // Make top scrollbar show rather than bottom
  .ps__rail-x {
    bottom: auto;
    top: 0px;
  }

  .ps--active-x table {
    padding: 1rem 0;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-radius: inherit;
    table-layout:fixed;
  }

  th {
    background-color: var(--cfa-purple-dark);
    color: var(--cfa-white);
    text-align: left;
    font-size: 1rem;
    min-width: 12rem;
    padding: 0.5rem;
  }

  td {
    padding: 0.5rem;
    font-size: 1rem;
    overflow-wrap: anywhere;
  }

  tr:nth-child(odd) {
    background: var(--cfa-purple-lightest);
  }

  .load-projects-button {
    text-align: center;
  }

  .tag-link {
    margin-right: 0.25em;
    &--active {
      background-color: var(--cfa-yellow-light);
    }
  }

  .hide-column-label {
    display:none;
  }

  @media screen and (max-width:800px) {
    .hide-column-label {
      display:inline;
    }
  }
    
 
  // .pagination {
  //   margin: 0.25rem 0.5rem;
  //   border-top: 1px solid var(--cfa-grey);
  //   text-align: center;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: flex-end;

  //   > * > * {
  //     display: inline-block;
  //     margin: 0.5rem;
  //   }
  // }
}
