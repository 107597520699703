
.checkbox input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 6px;
}


.checkbox input[type=checkbox]:checked:after {
  content: "✓";
  font-weight: bolder;
  font-size: 1.5rem;
  color: var(--cfa-blue);
  position: relative;
  top: -6px;
  left: -2px;
}

.checkbox input[type=checkbox]:hover,
.checkbox input[type=checkbox]:focus {
  background-color: var(--cfa-blue-lightest);
  cursor: pointer;
}