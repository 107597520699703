.unselected-tags {
  margin-left: 200px;
}
  .selected-tag-name {
    margin-right: 1rem;
  }
  
  .selected-tag-close {
    font-weight: bold;
    opacity: 0.75;
  }

  .selected-tag {
    margin-top: 0.5rem;
    padding: 0.3rem 0.5rem;

  
    &:hover,
    &:focus {
      .selected-tag-close {
        opacity: 1;
      }
    }
  }
.multi-select {

  ul {
    position: absolute;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 2px solid var(--cfa-blue-light);
    background-color: var(--cfa-white-light);
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 5px 5px;

    li {
      border: 2px solid var(--cfa-white-light);
      border-radius: 5px;
      padding: 0.5em;
      margin: 0;
      background-color: var(--cfa-blue-lightest);

      &:hover,
      &[aria-selected='true'] {
        background-color: var(--cfa-blue-lighter);
        cursor: pointer;
      }
    }
  }

  .clear-input {
    margin-left: 1rem;
  }

}
