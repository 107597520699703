:root {
  /* BRAND COLORS */
  --cfa-red: #cd1f42;
  --cfa-green: #00891b;
  --cfa-green-light: #00a175;
  --cfa-grey: #cfc5bf;
  --cfa-grey-dark: #5f5854;
  --cfa-grey-darkest: #121111;
  --cfa-magenta: #a6005e;
  --cfa-magenta-dark: #66013a;
  --cfa-magenta-light: hsl(326, 100%, 60%);
  --cfa-magenta-lighter: hsl(326, 100%, 92%);
  --cfa-magenta-lightest: hsl(326, 100%, 96%);
  --cfa-turqoise: #399fd3;
  --cfa-cyan: #4298bb;
  --cfa-teal: #008060;
  --cfa-teal-dark: #034e46;
  --cfa-yellow: #ffae00;
  --cfa-yellow-light: #ffe7b3;
  --cfa-orange: #e87d2b;
  --cfa-blue-lightest: #e6f7ff;
  --cfa-blue-lighter: #d5ebf6;
  --cfa-blue-light: #399fd3;
  --cfa-blue: #005980;
  --cfa-blue-dark: #004b6a;
  --cfa-purple-lightest: #f1eff6;
  --cfa-purple: #69579c;
  --cfa-purple-dark: #433762;
  --cfa-white: #e5e5e5;
  --cfa-white-light: hsl(0, 0%, 97%);

  /* DEFAULT COLORS */
  --color-background: var(--cfa-white-light);
  --color-text: var(--cfa-grey-darkest);
  --color-text-help: var(--cfa-grey-dark);
  --color-text-error: var(--cfa-red);

  /* BASE STYLES */
  background-color: var(--color-background);
  color: var(--color-text);
}
