.brigades-page-content {
  display: flex;
  margin-bottom: 3rem;
  height: 70vh;
  min-height: 400px;
  background-color: var(--cfa-purple-dark);
  border-radius: 5px;

  > * {
    background-color: white;
    margin: 4px;
  }

  .map {
    width: 40%;
    height: auto;
    border-radius: 5px 0 0 5px;
  }

  .projects-table {
    width: 60%;
    margin-left: 0;
    border-radius: 0 5px 5px 0;
  }

  @media only screen and (max-width: 600px) {
    display: unset;
    background-color: inherit;

    .map {
      height: 50vh;
      border: 1px solid var(--cfa-purple-dark);
    }

    .map,
    .projects-table {
      display: inline-block;
      width: 100%;
      border-radius: 5px;
    }
  }
}
