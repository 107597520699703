.form-control-container {
  &--inline {
    display: inline-block;
    width: max-content;
  }
}

.form-label {
  div {
    text-align: left;
  }

  &--inline {
    display: inline-block;
    margin-right: 0.5rem;
    align-self: center;
  }
}

.form-control {
  font-size: 1.15rem;
  border-radius: 5px;
  border: 2px solid var(--cfa-blue);
  background-color: var(--cfa-blue-lighter);
  padding: 0.3rem 0rem 0.3rem 0.25rem;
  margin-top: 3px;

  &:hover,
  &:focus {
    background-color: var(--cfa-blue-lightest);
  }

  &:disabled {
    cursor: default;
    color: var(--cfa-grey-dark);
    background-color: var(--cfa-grey);
    border: 2px solid var(--cfa-grey);
  }

  &--dark-background {
    // TODO: use for map reset button and nav bar
    background-color: var(--cfa-blue);
    color: var(--cfa-white);
    &:hover,
    &:focus {
      background-color: var(--cfa-blue-dark);
      color: var(--cfa-white);
    }
  }

  &--left {
    border-radius: 5px 0 0 5px;
  }

  &--right {
    border-radius: 0 5px 5px 0;
    border-left: none;
  }

  &--inline {
    display: inline-block;
  }
}
